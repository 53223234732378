import React, { useState } from 'react';
import { HStack, Textarea, Button, Text, VStack } from '@chakra-ui/react';
import { TextAreaFooterProps } from '../../types';

const TextAreaFooter = ({ onSendMessage, disclaimer }: TextAreaFooterProps) => {
    const [userInput, setUserInput] = useState('');

    const sendMessage = () => {
        if (userInput.trim() === '') {
            return;
        }
        onSendMessage(userInput);
        setUserInput('');
    };

    return (
        <VStack spacing={4} align="stretch">
            <HStack>
                <Textarea
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Type your message here"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault(); // Prevent newline for Enter alone.
                            sendMessage();
                        }
                    }}
                    flex={1}
                    resize="none"
                />
                <Button size={{ base: "sm", md: "md" }} colorScheme="teal" onClick={sendMessage}>
                    Send
                </Button>
            </HStack>
            <Text 
                textAlign="center" 
                fontSize={{ base: "xx-small", md: "x-small" }}
                color="gray.500" 
                width="100%">
                {disclaimer}
            </Text>
        </VStack>
    );
};

export default TextAreaFooter;
