import React, { useEffect, useRef, FunctionComponent } from 'react';
import { VStack, Text, Box, Flex } from '@chakra-ui/react';
import { ChatBoxProps } from '../../types';
import ChatLoading from './ChatLoading';
import Markdown from 'react-markdown';

const ChatBox: FunctionComponent<ChatBoxProps> = ({ chatMessages, isLoading }) => {
  const endOfMessagesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatMessages]);

  const containsList = (message: string): boolean => {
    const listRegex = /(\n-|\n\*|\n\d\.)/; // Matches Markdown list syntax
    return listRegex.test(message);
  };

  return (
    <Flex direction="column" flex={1} align="stretch">
      <VStack spacing={4} overflowY="auto" p={4} borderRadius="md" flex={1} align="stretch">
        {chatMessages.map((msg, index) => (
          <Box key={index} p={3} bg={msg.sender === 'AI' ? 'blue.50' : 'green.50'} borderRadius="lg">
            <Text color={msg.sender === 'AI' ? 'blue.700' : 'green.700'}>
              {containsList(msg.message) ? 
                <><strong>{msg.sender === 'AI' ? 'AI: ' : 'You: '}</strong>
                <Box pl={8}>
                  <Markdown>{msg.message}</Markdown>
                </Box></> : <Markdown>{(msg.sender === 'AI' ? '**AI:** ' : '**You:** ') + msg.message}</Markdown>}
            </Text>
          </Box>
        ))}
        <div ref={endOfMessagesRef} />
      </VStack>
      <Flex justify="flex-start" p={4}>
        {isLoading && <ChatLoading />}
      </Flex>
    </Flex>
  );
};

export default ChatBox;
