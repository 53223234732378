import React, { useState, useEffect } from 'react';
import { Flex, Box, Button, Image, Text, useToast, ModalOverlay, Modal, ModalContent, ModalBody, ModalHeader, ModalFooter, VStack, Spinner } from '@chakra-ui/react';
import { auth } from '../configurations/firebaseConfig';
import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import ChatBox from '../components/chat/ChatBox';
import TextAreaFooter from '../components/chat/TextAreaFooter';
import Header from '../components/chat/Header';
import { ChatMessage } from '../types';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CheckoutForm from '../components/CheckoutForm';
import setupPushNotifications from '../hooks/setupPushNotifications';
import appInsights from '../configurations/appInsights';

interface ChatPageProps {
    footerDisclaimer: string;
}

const ChatPage: React.FC<ChatPageProps> = ({ footerDisclaimer }) => {
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([{ sender: 'AI', message: 'Ask questions about your health and files' }]);
    const [isUserLoading, setIsUserLoading] = useState<boolean>(true);
    const [isMessagesLoading, setIsMessagesLoading] = useState<boolean>(false);
    const [user, setUser] = useState<User | null>(null);
    const navigate = useNavigate();
    const toast = useToast();
    const [paymentConfirmationDisplayed, setPaymentConfirmationDisplayed] = useState(false);
    const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [showCheckoutForm, setShowCheckoutForm] = useState(false);
    
    const isMobileDevice = () => {
        return /Android|iPhone|iPad|iPod|Windows Phone|IEMobile|Mobile|Opera Mini/i.test(navigator.userAgent);
    };

    const enableNotifications = () => {
        appInsights.trackEvent({ name: 'EnableNotificationsButtonClicked' });
        if ('Notification' in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    try {
                        setupPushNotifications();
                        localStorage.setItem('notificationEnabled', 'true');
                        setIsNotificationEnabled(true);
                        setShowNotificationModal(false);
                        toast({
                            title: 'Notifications Enabled',
                            description: 'You will now receive notifications for new messages.',
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        });
                    } catch (error) {
                        console.log('Could not subscribe user to notification.');
                    }
                } else {
                    console.log('Unable to get permission to notify.');
                }
            });
        } else {
            console.log('Notification API not supported in this browser.');
        }
    };

    const handleSignOut = () => {
        signOut(auth).then(() => {
            localStorage.removeItem('threadId');
            sessionStorage.removeItem('epicData');
            navigate('/');
        });
    };

    const handleNewMessage = (newMessage: ChatMessage) => {
        setChatMessages(prevMessages => [...prevMessages, newMessage]);
    };

    const handleSendMessage = async (userInput: string) => {
        appInsights.trackEvent({ name: 'SendMessageButtonClicked' });
        handleNewMessage({ sender: 'You', message: userInput });

        try {
            setIsMessagesLoading(true);
            const idToken = await auth.currentUser?.getIdToken();
            const threadId = localStorage.getItem('threadId');
            const storedEpicData = sessionStorage.getItem('epicData');
            const response = await axios.post('/chat',
                {
                    thread_id: threadId,
                    message: userInput,
                    epicData: storedEpicData
                },
                {
                    headers: { Authorization: `Bearer ${idToken}` }
                }
            );

            response.data?.responses?.forEach((response: string) => {
                handleNewMessage({ sender: 'AI', message: response });
            });
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 429) {
                    setShowCheckoutForm(true);
                    toast({
                        title: "Daily Limit Reached",
                        description: "Please subscribe to continue using the chat feature.",
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: "Error",
                        description: error.response.data.error || "An unexpected error occurred.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } else {
                console.error('Error:', error);
                toast({
                    title: "Error",
                    description: "An unexpected error occurred. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } finally {
            setIsMessagesLoading(false);
        }
    };

    useEffect(() => {
        appInsights.trackPageView({ name: 'ChatPage' });
    }, []);

    useEffect(() => {
        const setupUserData = async (user: User, data: any) => {
            setUser(user);
            const localNotificationEnabled = localStorage.getItem('notificationEnabled') === 'true';
            setIsNotificationEnabled(localNotificationEnabled);
            if (!localNotificationEnabled) {
                setShowNotificationModal(true);
            }
            if (data.threadId) localStorage.setItem('threadId', data.threadId);
        };

        const fetchUserData = async (user: User) => {
            try {
                const idToken = await user.getIdToken();
                const response = await axios.get('/get-user', { headers: { Authorization: `Bearer ${idToken}` } });
                await setupUserData(user, response.data);
            } catch (error) {
                console.error("Error fetching user data: ", error);
                clearLocalData();
                navigate('/');
            } finally {
                setIsUserLoading(false);
            }
        };

        const clearLocalData = () => {
            localStorage.removeItem('threadId');
            sessionStorage.removeItem('epicData');
        };

        const authObserver = onAuthStateChanged(auth, async user => {
            if (user) {
                await fetchUserData(user);
            } else {
                clearLocalData();
                navigate('/');
            }
        });

        return () => authObserver();
    }, [navigate]);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        if (sessionId && !paymentConfirmationDisplayed) {
            axios.get(`/session-status?session_id=${sessionId}`)
                .then((response) => {
                    const data = response.data;
                    if (data.status === 'complete') {
                        toast({
                            title: "Payment Successful",
                            description: `We appreciate your business! A confirmation email will be sent to ${data.customer_email}.`,
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                        });
                        setPaymentConfirmationDisplayed(true);
                    }
                })
                .catch((error) => {
                    console.error('Error checking session status:', error);
                    toast({
                        title: "Error",
                        description: "There was an issue checking the payment status. Please try again.",
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                    });
                });
        }
    }, [paymentConfirmationDisplayed, toast]);

    if (isUserLoading) {
        return <Spinner />;
    }

    return (
        <Flex direction="column" h="100vh" align="center" px={4}>
            <Header 
              enableNotifications={enableNotifications} 
              isNotificationEnabled={isNotificationEnabled} 
            />
            {user && (
                <Flex align="center" justify="space-between" w="full" maxW="2xl" p={4}>
                    <Text>{user.displayName || user.email}</Text>
                    {user.photoURL && <Image src={user.photoURL} borderRadius="full" boxSize="40px" />}
                    <Button size={{ base: "sm", md: "md" }} onClick={handleSignOut}>Sign Out</Button>
                </Flex>
            )}
            <Box w="full" maxW="2xl" flex="1" overflowY="auto" borderRadius="lg" my={4}>
                <ChatBox chatMessages={chatMessages} isLoading={isMessagesLoading} />
            </Box>
            {showCheckoutForm ? (
                <CheckoutForm />
            ) : (
                <Box w="full" maxW="2xl" pb={4}>
                <TextAreaFooter onSendMessage={handleSendMessage} disclaimer={footerDisclaimer} />
                </Box>
            )}
            <Modal isOpen={showNotificationModal} onClose={() => setShowNotificationModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Enable Notifications</ModalHeader>
                    <ModalBody>
                        {isMobileDevice() ? (
                            <VStack spacing={3} align="start">
                                <Text>To receive timely health insights and follow-up reminders:</Text>
                                <Box as="ol" pl={5}>
                                    <Text as="li">Tap the share icon in your browser.</Text>
                                    <Text as="li">Select "Add to Home Screen."</Text>
                                    <Text as="li">Visit the app by tapping the app icon from your home screen.</Text>
                                    <Text as="li">Enable notifications from the menu.</Text>
                                </Box>
                            </VStack>

                        ) : (
                            <Text>
                                To receive timely health insights and follow-up reminders, please enable notifications.
                            </Text>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        {!isMobileDevice() && (
                            <Button colorScheme="blue" mr={3} onClick={enableNotifications}>
                                Enable Notifications
                            </Button>
                        )}
                        <Button variant="ghost" onClick={() => setShowNotificationModal(false)}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default ChatPage;
