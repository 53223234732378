import React, { useState } from 'react';
import axios from 'axios';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    VStack,
    Heading,
    useToast
} from '@chakra-ui/react';
import { File } from '../../types';
import { auth } from '../../configurations/firebaseConfig';
import Markdown from 'react-markdown';
import appInsights from '../../configurations/appInsights';

interface MedicalSummaryModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const MedicalSummaryModal: React.FC<MedicalSummaryModalProps> = ({ isOpen, onClose }) => {
    const [medicalSummary, setMedicalSummary] = useState('');
    const [files, setFiles] = useState<File[]>();
    const toast = useToast();

    React.useEffect(() => {
        const getMedicalSummary = async () => {
            if (!isOpen) return;
            
            appInsights.trackEvent({ name: 'ViewMedicalSummaryButtonClicked' });
            try {
                const idToken = await auth.currentUser?.getIdToken();
                const response = await axios.get('/medical_summary', { 
                    headers: { Authorization: `Bearer ${idToken}` } 
                });
                const formattedSummary = response.data?.summary ?? 'No patient summary.';
                const files = response.data?.files;

                setMedicalSummary(formattedSummary);
                setFiles(files);
            } catch (error) {
                console.error('Error:', error);
                toast({
                    title: 'Error retrieving medical summary.',
                    description: 'There was an error retrieving medical summary.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        getMedicalSummary();
    }, [isOpen, toast]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Medical Summary</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4}>
                        <Markdown>{medicalSummary}</Markdown>
                        {files && files.length > 0 && (
                            <Heading size="sm" mt={6}>File Summaries</Heading>
                        )}
                        {files?.map((file, index) => (
                            <Markdown key={index}>{file.summary}</Markdown>
                        ))}
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default MedicalSummaryModal;