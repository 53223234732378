import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Button,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { ParsedEpicData } from '../types';

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const epicData: ParsedEpicData = JSON.parse(sessionStorage.getItem('epicData') || '{}');
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString();
  };

  const renderLabResults = (labs: ParsedEpicData['labs']) => {
    const sortedLabs = [...labs].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    
    const formatReferenceRange = (range: string) => {
      if (!range) return 'N/A';
      if (range.startsWith('- ')) {
        return `0 ${range}`;
      }
      return range;
    };

    return (
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Test</Th>
            <Th>Value</Th>
            <Th>Unit</Th>
            <Th>Reference Range</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sortedLabs.map((lab, index) => (
            <Tr key={index}>
              <Td>{formatDate(lab.date)}</Td>
              <Td>{lab.test}</Td>
              <Td>{lab.value}</Td>
              <Td>{lab.unit}</Td>
              <Td>{formatReferenceRange(lab.referenceRange)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  };

  const renderMedications = (medications: ParsedEpicData['medications']) => (
    <VStack align="start" spacing={3}>
      {medications.map((med, index) => (
        <Box key={index}>
          <Text fontWeight="bold">{med.name}</Text>
          <Text fontSize="sm" color="gray.500">{med.dosage}</Text>
          <Text fontSize="xs" color="gray.400">
            Status: {med.status} | 
            Last Updated: {formatDate(med.lastUpdated)}
          </Text>
        </Box>
      ))}
    </VStack>
  );

  const renderConditions = (conditions: ParsedEpicData['conditions']) => (
    <VStack align="start" spacing={4}>
      {conditions.map((condition, index) => (
        <Box key={index} p={3} borderWidth={1} borderRadius="md" width="100%">
          <Text fontWeight="bold">{condition.name}</Text>
          <Text fontSize="sm">Onset: {formatDate(condition.onset)}</Text>
          <Text fontSize="sm">Clinical Status: {condition.clinicalStatus}</Text>
          <Text fontSize="sm">Verification Status: {condition.verificationStatus}</Text>
        </Box>
      ))}
    </VStack>
  );

  const renderVitals = (vitals: ParsedEpicData['vitals']) => (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Vital Sign</Th>
          <Th>Value</Th>
          <Th>Unit</Th>
          <Th>Date</Th>
        </Tr>
      </Thead>
      <Tbody>
        {vitals.map((vital, index) => (
          <Tr key={index}>
            <Td>{vital.name}</Td>
            <Td>{vital.value}</Td>
            <Td>{vital.unit}</Td>
            <Td>{formatDate(vital.date)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  const renderEncounters = (encounters: ParsedEpicData['encounters']) => (
    <Accordion allowMultiple>
      {encounters.map((encounter, index) => (
        <AccordionItem key={index}>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                {encounter.type} - {formatDate(encounter.startDate)}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Text><strong>Provider:</strong> {encounter.provider || 'N/A'}</Text>
            <Text><strong>Reason:</strong> {encounter.reason || 'N/A'}</Text>
            <Text><strong>Status:</strong> {encounter.status}</Text>
            <Text><strong>Class:</strong> {encounter.class}</Text>
            <Text><strong>End Date:</strong> {encounter.endDate ? formatDate(encounter.endDate) : 'N/A'}</Text>
            <Text><strong>Outcome:</strong> {encounter.outcome || 'N/A'}</Text>
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box bg={bgColor} minH="100vh" py={5}>
      <Container maxW="container.xl">
        <Flex justifyContent="space-between" alignItems="center" mb={6}>
          <Button leftIcon={<ArrowBackIcon />} onClick={handleBack} variant="outline">
            Back
          </Button>
          <Heading textAlign="center">MyChart Dashboard</Heading>
          <Box width={20} />
        </Flex>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={6}>
          {epicData.labs && (
            <Box bg={cardBgColor} p={5} borderRadius="lg" boxShadow="md">
              <Heading size="md" mb={4}>Lab Results</Heading>
              {renderLabResults(epicData.labs)}
            </Box>
          )}
          {epicData.medications && (
            <Box bg={cardBgColor} p={5} borderRadius="lg" boxShadow="md">
              <Heading size="md" mb={4}>Current Medications</Heading>
              {renderMedications(epicData.medications)}
            </Box>
          )}
          {epicData.conditions && (
            <Box bg={cardBgColor} p={5} borderRadius="lg" boxShadow="md">
              <Heading size="md" mb={4}>Conditions</Heading>
              {renderConditions(epicData.conditions)}
            </Box>
          )}
          {epicData.vitals && (
            <Box bg={cardBgColor} p={5} borderRadius="lg" boxShadow="md">
              <Heading size="md" mb={4}>Vitals</Heading>
              {renderVitals(epicData.vitals)}
            </Box>
          )}
          {epicData.encounters && (
            <Box bg={cardBgColor} p={5} borderRadius="lg" boxShadow="md">
              <Heading size="md" mb={4}>Recent Encounters</Heading>
              {renderEncounters(epicData.encounters)}
            </Box>
          )}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default DashboardPage;
