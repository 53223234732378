import axios from 'axios';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAuth } from 'firebase/auth';
import { app } from '../configurations/firebaseConfig';

const setupPushNotifications = () => {
  const messaging = getMessaging(app);
  const auth = getAuth(app);
  getToken(messaging, { vapidKey: 'BMXBb8OiYVC1U4Nxw2M26jZC6Idr9eZ4AaCIaAzB-HsxKkftH3pOVFk05uEBvQCMee4Hs1lZCpd6UIH5LRAT_r4' })
    .then(async (currentToken) => {
      if (currentToken) {
        console.log('Token:', currentToken);
        const idToken = await auth.currentUser?.getIdToken();
        axios.post('/subscribe_to_notifications', 
          {
            token: currentToken,
          },
          {
            headers: { Authorization: `Bearer ${idToken}` }
          })
          .then(response => {
            console.log('Token saved:', response.data);
          })
          .catch(error => {
            console.error('Error saving token:', error);
            throw error;
          });
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.error('An error occurred while retrieving token:', err);
      throw err;
    });

  onMessage(messaging, (payload) => {
    console.log('Message received:', payload);
  });
};

export default setupPushNotifications;
