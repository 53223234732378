import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatPage from './routes/ChatPage';
import axios from 'axios';
import Landing from './routes/Landing';
import SignUpForm from './components/signup/SignUpForm';
import PrivacyPolicyPage from './routes/PrivacyPolicyPage';
import DashboardPage from './routes/DashboardPage';

const App = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_SERVER_URL;
  const footerDisclaimer = "Healoop provides health insights; always consult your doctor before following any advice or suggestions given.";

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' })
      .then(function (registration) {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch(function (err) {
        console.error('Service worker registration failed, error:', err);
      });
  }

  return (
    <Router>
      <Routes>
        <Route path="/chat" element={<ChatPage footerDisclaimer={footerDisclaimer} />} />
        <Route path="/signup" element={<SignUpForm />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/" element={<Landing />} />
      </Routes>
    </Router>
  );
};

export default App;
