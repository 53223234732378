import { Box, Container, Flex, Heading, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { BsCheckCircle } from "react-icons/bs"
import SignIn from "./SignIn"
import home1 from "../assets/home1.jpeg"
import { THEME } from "../../theme/colors"

const HeroSection = () => {
  return (
    <Box minH={{ base: 'calc(100vh - 64px)', md: '100vh' }} display="flex" alignItems="center">
      <Container maxW="7xl">
        <Stack
          align="center"
          spacing={{ base: 8, md: 10 }}
          direction={{ base: 'column', md: 'row' }}
          pt={{ base: 20, md: 0 }}
        >
          {/* Mobile Image (Top) */}
          <Flex
            display={{ base: 'flex', md: 'none' }}
            w="full"
            justify="center"
            mb={6}
          >
            <Box
              position="relative"
              height="200px"
              width="200px"
              rounded="2xl"
              overflow="hidden"
            >
              <img src={home1} alt="Hero" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
            </Box>
          </Flex>

          <Stack flex={1} spacing={{ base: 5, md: 10 }} align={{ base: 'center', md: 'flex-start' }}>
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '3xl', lg: '6xl' }}
              textAlign={{ base: 'center', md: 'left' }}
            >
              <Text as="span" position="relative">
                Your personal{' '}
                <Text 
                  as="span" 
                  bg={THEME.colors.highlight} 
                  px={2} 
                  py={1} 
                  borderRadius="md"
                  position="relative"
                  _after={{
                    content: '""',
                    position: 'absolute',
                    bottom: '-2px',
                    left: '0',
                    right: '0',
                    height: '2px',
                    bg: THEME.primary,
                    borderRadius: 'full'
                  }}
                >
                  AI
                </Text>
                {' '}medical assistant
              </Text>
            </Heading>
            <Stack spacing={4} w="full" align={{ base: 'center', md: 'flex-start' }}>
              <HStack align="center" spacing={3}>
                <Icon as={BsCheckCircle} color={THEME.primary} w={5} h={5} />
                <Text color="gray.600">Chat directly with your medical data</Text>
              </HStack>
              <HStack align="center" spacing={3}>
                <Icon as={BsCheckCircle} color={THEME.primary} w={5} h={5} />
                <Text color="gray.600">Get instant, personalized health answers</Text>
              </HStack>
              <HStack align="center" spacing={3}>
                <Icon as={BsCheckCircle} color={THEME.primary} w={5} h={5} />
                <Text color="gray.600">Access offline health insights anytime</Text>
              </HStack>
            </Stack>
            <Box 
              w={{ base: 'full', md: 'auto' }}
              display="flex"
              justifyContent={{ base: 'center', md: 'flex-start' }}
              sx={{
                '& > div': {
                  display: 'flex',
                  justifyContent: 'center',
                  w: 'full'
                }
              }}
            >
              <SignIn buttonText="Get started for free" />
            </Box>
          </Stack>

          {/* Desktop Image (Right) */}
          <Flex
            flex={1}
            justify="center"
            align="center"
            position="relative"
            w="full"
            display={{ base: 'none', md: 'flex' }}
          >
            <Box
              position="relative"
              height="400px"
              rounded="2xl"
              width="full"
              overflow="hidden"
            >
              <img src={home1} alt="Hero" style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
            </Box>
          </Flex>
        </Stack>
      </Container>
    </Box>
  )
}

export default HeroSection 