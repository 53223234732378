import { EpicData, ParsedEpicData } from '../types';

class EpicDataParser {
  private epicData: EpicData;

  constructor(epicData: EpicData) {
    this.epicData = epicData;
  }

  parse(): ParsedEpicData {
    return {
      labs: this.parseLabs(),
      vitals: this.parseVitals(),
      medications: this.parseMedications(),
      conditions: this.parseConditions(),
      encounters: this.parseEncounters(),
    };
  }

  private parseLabs() {
    return (this.epicData.labs.entry || [])
      .map((lab: any) => {
        const resource = lab.resource;
        const coding = resource?.code?.coding?.[0];
        const valueQuantity = resource?.valueQuantity;
        const referenceRange = resource?.referenceRange?.[0];

        if (!coding || !valueQuantity) return null;

        return {
          test: coding.display || resource?.code?.text,
          value: valueQuantity.value,
          unit: valueQuantity.unit,
          referenceRange: referenceRange ? 
            `${referenceRange.low?.value || ''} - ${referenceRange.high?.value || ''}`.trim() :
            undefined,
          date: resource?.effectiveDateTime,
        };
      })
      .filter(Boolean);
  }

  private parseVitals() {
    return (this.epicData.vitals.entry || [])
      .flatMap((vital: any) => {
        const resource = vital.resource;
        const coding = resource?.code?.coding?.[0];
        const valueQuantity = resource?.valueQuantity;
        const components = resource?.component || [];

        if (valueQuantity) {
          return this.createVitalEntry(coding, valueQuantity, resource?.effectiveDateTime);
        } else if (components.length > 0) {
          return components
            .map((component: any) => this.createVitalEntry(
              component.code?.coding?.[0],
              component.valueQuantity,
              resource?.effectiveDateTime
            ))
            .filter(Boolean);
        }
        return [];
      })
      .filter(Boolean);
  }

  private createVitalEntry(coding: any, valueQuantity: any, date: string) {
    if (!coding || !valueQuantity) return null;
    return {
      name: coding.display || coding.text,
      value: valueQuantity.value,
      unit: valueQuantity.unit,
      date,
    };
  }

  private parseMedications() {
    return (this.epicData.medications.entry || [])
      .map((med: any) => {
        const resource = med.resource;
        const name = resource?.medicationCodeableConcept?.text || 
                     resource?.medicationCodeableConcept?.coding?.[0]?.display ||
                     resource?.medicationReference?.display;
        
        if (!name) return null;

        return {
          name,
          dosage: resource?.dosageInstruction?.[0]?.text,
          status: resource?.status,
          lastUpdated: resource?.authoredOn,
        };
      })
      .filter(Boolean)
      .slice(0, 5);
  }

  private parseConditions() {
    return (this.epicData.conditions.entry || [])
      .map((condition: any) => {
        const resource = condition.resource;
        const name = resource?.code?.text || resource?.code?.coding?.[0]?.display;
        
        if (!name) return null;

        return {
          name,
          onset: resource?.onsetDateTime,
          clinicalStatus: resource?.clinicalStatus?.coding?.[0]?.display,
          verificationStatus: resource?.verificationStatus?.coding?.[0]?.display,
        };
      })
      .filter(Boolean);
  }

  private parseEncounters() {
    return (this.epicData.encounters.entry || [])
      .map((encounter: any) => {
        const resource = encounter.resource;
        const type = resource?.type?.[0]?.text || 'Encounter';
        const startDate = resource?.period?.start;
        
        if (!startDate) return null;

        return {
          type,
          startDate,
          endDate: resource?.period?.end,
          status: resource?.status,
          class: resource?.class?.display,
          provider: resource?.participant?.[0]?.individual?.display || 'Unknown',
          reason: resource?.reasonCode?.[0]?.text || 'Not specified',
          outcome: resource?.outcome?.text || 'Not recorded'
        };
      })
      .filter(Boolean);
  }
}

export default EpicDataParser;
