import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Textarea,
  useToast,
  Center,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth } from '../../configurations/firebaseConfig';
import { onAuthStateChanged, User } from 'firebase/auth';
import Logo from '../assets/Logo';

interface ProfileFormState {
  age: string;
  gender: string;
  healthInterest: string;
  medicalSummary: string;
}

const SignUpForm = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false)
  const [formState, setFormState] = useState<ProfileFormState>({
    age: '',
    gender: '',
    healthInterest: '',
    medicalSummary: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { age, gender, healthInterest, medicalSummary } = formState;
    const profileSummary = `Age: ${age}, Gender: ${gender}, Health Interest: ${healthInterest}, Medical Summary: ${medicalSummary}`;

    try {
      setIsLoading(true);
      const idToken = await auth.currentUser?.getIdToken();
      const response = await axios.post('/initialize', {
        profile: profileSummary
      }, { headers: { Authorization: `Bearer ${idToken}` } });

      toast({
        title: "Profile submitted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      navigate('/chat', { state: { ...response.data } });
    } catch (error) {
      toast({
        title: "Error submitting profile.",
        description: "An error occurred. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
      if (!user) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  return (
    <Box p={5} maxW="500px" borderWidth="1px" borderRadius="lg" overflow="hidden" mx="auto" my="20">
      <Center mb={6}>
        <Logo height='65' width='180' />
      </Center>
      <form onSubmit={handleSubmit}>
        <FormControl isRequired mb={4}>
          <FormLabel>Age</FormLabel>
          <Input name="age" placeholder="Your age" type="number" onChange={handleChange} value={formState.age} />
        </FormControl>
        <FormControl isRequired mb={4}>
          <FormLabel>Gender</FormLabel>
          <Select name="gender" placeholder="Select gender" onChange={handleChange} value={formState.gender}>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
            <option value="Prefer not to say">Prefer not to say</option>
          </Select>
        </FormControl>
        <FormControl isRequired mb={4}>
          <FormLabel>Health Interest(s)</FormLabel>
          <Input name="healthInterest" placeholder=" general insights, women's health, diabetes management" onChange={handleChange} value={formState.healthInterest} />
        </FormControl>
        <FormControl isRequired mb={4}>
          <FormLabel>Medical Summary</FormLabel>
          <Textarea
            name="medicalSummary"
            placeholder="Include key details such as weight, location, fitness plan, allergies, medications, medical history, family history etc. The more context you provide, the better the responses and insights!"
            onChange={handleChange}
            value={formState.medicalSummary}
            height={{ base: "130px", md: "200px" }}  // Set the height to your preference
          />
        </FormControl>

        <Button isLoading={isLoading} mt={4} colorScheme="blue" type="submit">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default SignUpForm;
