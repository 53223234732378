import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const ChatLoading = () => {
  return (
    <div className="chat-loading">
      <ThreeDots color="#2c5282" height={50} width={50} />
    </div>
  );
};

export default ChatLoading;
