import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import axios from "axios";
import { auth } from '../configurations/firebaseConfig';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string);

const CheckoutForm = () => {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const createCheckoutSession = async () => {
      try {
        const idToken = await auth.currentUser?.getIdToken(true);
        const response = await axios.post("/create-checkout-session", {}, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
    };

    createCheckoutSession();
  }, []);

  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
}

export default CheckoutForm;