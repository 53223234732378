import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();  // Track initial page view

export default appInsights;
