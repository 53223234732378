import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { auth } from '../../configurations/firebaseConfig';
import { signOut } from 'firebase/auth';
import {
    Box, Flex, Text, VStack, IconButton, Menu, MenuButton, MenuList, MenuItem,
    HStack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, useDisclosure,
    useToast
} from '@chakra-ui/react';
import { HamburgerIcon, WarningIcon } from '@chakra-ui/icons';
import MedicalSummaryModal from './MedicalSummaryModal';
import FileAnalysisModal from './FileAnalysisModal';
import appInsights from '../../configurations/appInsights';
import Logo from '../assets/Logo';
import EpicConnection from '../epic/EpicConnection';

interface HeaderProps {
    enableNotifications: () => void;
    isNotificationEnabled: boolean;
}

const Header: React.FC<HeaderProps> = ({ enableNotifications, isNotificationEnabled }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isMedicalSummaryOpen, onOpen: onMedicalSummaryOpen, onClose: onMedicalSummaryClose } = useDisclosure();
    const { isOpen: isCancelModalOpen, onOpen: onCancelModalOpen, onClose: onCancelModalClose } = useDisclosure();
    const toast = useToast();
    const [hasPaid, setHasPaid] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const idToken = await auth.currentUser?.getIdToken();
                const response = await axios.get('/get-user', {
                    headers: { Authorization: `Bearer ${idToken}` }
                });
                setHasPaid(response.data.hasPaid);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []);

    const handleDeleteAccount = async () => {
        appInsights.trackEvent({ name: 'DeleteAccountButtonClicked' });
        try {
            const idToken = await auth.currentUser?.getIdToken();
            await axios.delete('/delete_account', { headers: { Authorization: `Bearer ${idToken}` } });
            await signOut(auth);
            localStorage.clear();
            toast({
                title: "Account Deleted",
                description: "Your account has been successfully deleted.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Account Deletion Failed",
                description: "An error occurred while trying to delete your account. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            onClose();
        }
    };

    const handleCancelSubscription = async () => {
        appInsights.trackEvent({ name: 'CancelSubscriptionButtonClicked' });
        onCancelModalOpen();
    };

    const confirmCancelSubscription = async () => {
        try {
            const idToken = await auth.currentUser?.getIdToken();
            await axios.post('/cancel-subscription', {}, { headers: { Authorization: `Bearer ${idToken}` } });
            setHasPaid(false);
            toast({
                title: "Subscription Canceled",
                description: "Your subscription has been successfully canceled.",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error canceling subscription:', error);
            toast({
                title: "Cancellation Failed",
                description: "An error occurred while trying to cancel your subscription. Please try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            onCancelModalClose();
        }
    };

    return (
        <Box p={6} textAlign="center">
            <Flex justifyContent="space-between" alignItems="center">
                <Flex flex="1" justifyContent="center">
                    <Logo height='60' width='180' />
                </Flex>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<HamburgerIcon />}
                        variant="outline"
                        position="absolute"
                        top={4}
                        right={5}
                    />
                    <MenuList>
                        {!isNotificationEnabled && <MenuItem onClick={enableNotifications}>Enable Notifications</MenuItem>}
                        <MenuItem onClick={onMedicalSummaryOpen}>View Medical Summary</MenuItem>
                        <MenuItem as='a' href='https://forms.gle/iumAQvdNHRbqYyr97' target='_blank'>Feedback & Feature Requests</MenuItem>
                        {hasPaid && <MenuItem onClick={handleCancelSubscription}>Cancel Subscription</MenuItem>}
                        <MenuItem onClick={onOpen}>Delete Account</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>
            <Text fontSize="md" color="gray.600" mb={4}>Your personal AI medical assistant.</Text>
            <VStack spacing={4}>
                <HStack spacing={3} justify="center">
                    <FileAnalysisModal />
                    <EpicConnection />
                </HStack>
            </VStack>

            <MedicalSummaryModal isOpen={isMedicalSummaryOpen} onClose={onMedicalSummaryClose} />

            {/* Delete Account Confirmation Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete Account</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete your account? This action is irreversible and will result in the loss of all your data.
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleDeleteAccount}>
                            Yes, delete my account
                        </Button>
                        <Button variant="ghost" onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            {/* Cancel Subscription Confirmation Modal */}
            <Modal isOpen={isCancelModalOpen} onClose={onCancelModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Cancel Subscription</ModalHeader>
                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                            <HStack>
                                <WarningIcon color="orange.500" />
                                <Text fontWeight="bold">Are you sure you want to cancel your subscription?</Text>
                            </HStack>
                            <Text>
                                This action will immediately end your access to premium features. 
                                You can resubscribe at any time, but you may lose access to some of your data.
                            </Text>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={confirmCancelSubscription}>
                            Yes, cancel my subscription
                        </Button>
                        <Button variant="ghost" onClick={onCancelModalClose}>Keep my subscription</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default Header;
