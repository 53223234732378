import React, { useState, ChangeEvent } from 'react';
import axios from 'axios';
import {
    Button, Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, Input, useToast, useDisclosure, Spinner
} from '@chakra-ui/react';
import FileAnalysisResultModal from './FileAnalysisResultModal';
import { auth } from '../../configurations/firebaseConfig';
import appInsights from '../../configurations/appInsights';


const FileAnalysisModal = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isResultsModalOpen, setResultsModalOpen] = useState(false);
    const [analysisResult, setAnalysisResult] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const uploadFile = async () => {
        appInsights.trackEvent({ name: 'AnalyzeMedicalFileButtonClicked' });
        if (!selectedFile) {
            toast({
                title: 'No file selected.',
                description: 'Please select a file to upload.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        setIsUploading(true); // Start spinner before uploading

        try {
            const idToken = await auth.currentUser?.getIdToken();
            const response = await axios.post('/analyze_file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${idToken}` 
                },
            });
            setIsUploading(false);
            setAnalysisResult(response.data.summary);
            onClose();
            setResultsModalOpen(true);
        } catch (error) {
            setIsUploading(false);
            console.error('Error:', error);
            toast({
                title: 'Upload Failed',
                description: 'There was an error uploading your file.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
            <Button size={{ base: "sm", md: "md" }} onClick={onOpen} colorScheme="blue">Analyze Medical Files</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Upload Medical Files</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {isUploading ? (
                            <Spinner size="xl" />
                        ) : (
                            <Input
                                type="file"
                                p={1}
                                onChange={handleFileChange}
                            />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>Close</Button>
                        <Button colorScheme="blue" onClick={uploadFile} isDisabled={isUploading}>Upload</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <FileAnalysisResultModal isOpen={isResultsModalOpen} onClose={() => setResultsModalOpen(false)} analysisResult={analysisResult} />
        </>
    );
};

export default FileAnalysisModal;
