// Modern, professional healthcare color palette options

// Theme 1: Social Media Inspired (Default)
// Perfect for: Modern tech platforms, social engagement, broad appeal
export const THEME_SOCIAL = {
  primary: "rgb(29, 155, 240)",  // Twitter-inspired blue: modern, trusted, and globally recognized
  colors: {
    highlight: "rgba(29, 155, 240, 0.1)",    // Soft, eye-friendly highlight
    border: "rgba(29, 155, 240, 0.2)",       // Subtle, professional borders
    hover: "rgb(26, 140, 216)",              // Slightly deeper for hover states
    bg: "rgba(29, 155, 240, 0.05)"           // Ultra-soft background for extended viewing
  }
}

// Theme 2: Healthcare Focused
// Perfect for: Medical platforms, wellness apps, healthcare services
export const THEME_HEALTH = {
  primary: "rgb(82, 153, 167)",  // Softer blue: calming, healthcare-focused
  colors: {
    highlight: "rgba(82, 153, 167, 0.1)",    // Gentle highlight
    border: "rgba(82, 153, 167, 0.2)",       // Soft borders
    hover: "rgb(74, 138, 150)",              // Calming hover state
    bg: "rgba(82, 153, 167, 0.05)"           // Subtle background
  }
}

// Active theme - Switch between THEME_SOCIAL and THEME_HEALTH here
export const THEME = THEME_HEALTH

// Theme characteristics:
// THEME_SOCIAL:
// - Perfect Social Media Blue: Proven to work across millions of users
// - Universal Appeal: Widely recognized and trusted
// - Modern Tech: Associated with leading digital platforms
// - High Energy: Great for engagement and calls to action

// THEME_HEALTH:
// - Softer Healthcare Blue: Calming and trustworthy
// - Professional: Medical and wellness focused
// - Easy on Eyes: Perfect for longer reading sessions
// - Approachable: Balances authority with accessibility

// Usage for both themes:
// Buttons, CTAs: primary
// Text highlights: highlight
// Borders: border
// Icon backgrounds: bg
// Hover states: hover