import React from 'react';
import {
    Modal, ModalOverlay, ModalContent, ModalHeader,
    ModalFooter, ModalBody, ModalCloseButton, Button
} from '@chakra-ui/react';
import { FileAnalysisResultModalProps } from '../../types';
import Markdown from 'react-markdown';

const FileAnalysisResultModal = ({ isOpen, onClose, analysisResult }: FileAnalysisResultModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Analyzed Result</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Markdown>{analysisResult}</Markdown>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default FileAnalysisResultModal;
