import { Box } from "@chakra-ui/react"
import NavHeader from "../components/home/NavHeader"
import HeroSection from "../components/home/HeroSection"
import FeaturesSection from "../components/home/FeaturesSection"
import PricingSection from "../components/home/PricingSection"
import Footer from "../components/home/Footer"

export default function Landing() {
  return (
    <Box w="full">
      <NavHeader />
      <HeroSection />
      <FeaturesSection />
      <PricingSection />
      <Footer />
    </Box>
  )
}
