import { Box, Container, Grid, Heading, HStack, Icon, Text, VStack, useColorModeValue } from "@chakra-ui/react"
import { BsCheckCircle } from "react-icons/bs"
import SignIn from "./SignIn"
import { THEME } from "../../theme/colors"

interface PricingCardProps {
  title: string
  price: string
  features: string[]
  isPro?: boolean
}

const PricingCard = ({ title, price, features, isPro = false }: PricingCardProps) => {
  return (
    <Box
      maxW="330px"
      w="full"
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={isPro ? "xl" : "lg"}
      rounded="lg"
      p={6}
      textAlign="center"
      border={isPro ? `2px solid ${THEME.colors.border}` : "none"}
      transform={isPro ? "scale(1.05)" : "none"}
      transition="all 0.3s"
      _hover={{
        transform: isPro ? "scale(1.08)" : "scale(1.03)",
        boxShadow: "2xl"
      }}
    >
      <Heading as="h3" size="lg" mb={4}>{title}</Heading>
      <VStack spacing={2} align="stretch" mb={6}>
        <Text fontSize="3xl" fontWeight="bold">
          {price}
          {price.includes("$") && <Text as="span" fontSize="md" color="gray.500">/month</Text>}
        </Text>
      </VStack>
      <VStack spacing={3} align="start" mb={8}>
        {features.map((feature, index) => (
          <HStack key={index}>
            <Icon as={BsCheckCircle} color={THEME.primary} />
            <Text color="gray.600">{feature}</Text>
          </HStack>
        ))}
      </VStack>
      <Box>
        <SignIn buttonText="Get started" />
      </Box>
    </Box>
  )
}

const PricingSection = () => {
  return (
    <Box id="pricing" py={20}>
      <Container maxW="7xl">
        <VStack spacing={12}>
          <Box textAlign="center">
            <Heading mb={5}>Simple, Transparent Pricing</Heading>
            <Text color="gray.600" fontSize="lg">
              Start with our free plan or upgrade for premium features
            </Text>
          </Box>
          
          <Grid templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }} gap={8} justifyItems="center">
            <PricingCard
              title="Free"
              price=""
              features={[
                "Limited messages with assistant",
                "No external data integration",
                "Limited health profile context",
              ]}
            />
            <PricingCard
              title="Pro"
              price="$4.99"
              features={[
                "MyChart records integration",
                "Unlimited AI messages",
              ]}
              isPro={true}
            />
          </Grid>
        </VStack>
      </Container>
    </Box>
  )
}

export default PricingSection 