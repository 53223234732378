import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyA_ku-uYc9fbqIwzR9CoL5dJBt69I8hjpQ",
  authDomain: "healoop-77e58.firebaseapp.com",
  projectId: "healoop-77e58",
  storageBucket: "healoop-77e58.appspot.com",
  messagingSenderId: "80819153206",
  appId: "1:80819153206:web:334cb9ab8bfa775bfeab97",
  measurementId: "G-BH4R6YGQ9Y"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { app, auth, analytics };
