import { Box, Container, Grid, Heading, Icon, Stack, Text, VStack, Flex } from "@chakra-ui/react"
import { FaRobot, FaChartLine, FaLock, FaLink } from "react-icons/fa"
import { THEME } from "../../theme/colors"

interface FeatureProps {
  title: string
  text: string
  icon: any
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack spacing={4} align="center" textAlign="center">
      <Flex
        w={16}
        h={16}
        align="center"
        justify="center"
        color={THEME.primary}
        rounded="full"
        bg={THEME.colors.bg}
        mb={1}
        transition="all 0.3s"
        _hover={{
          transform: 'scale(1.05)',
          bg: THEME.colors.highlight,
        }}
      >
        <Icon as={icon} w={8} h={8} />
      </Flex>
      <Text fontWeight={600} fontSize="lg">{title}</Text>
      <Text color="gray.600">{text}</Text>
    </Stack>
  )
}

const FeaturesSection = () => {
  return (
    <Box id="features" bg="gray.50" py={20}>
      <Container maxW="7xl">
        <VStack spacing={12}>
          <Box textAlign="center">
            <Heading mb={5}>Why Choose Healoop?</Heading>
            <Text color="gray.600" fontSize="lg">
              Your personal AI health assistant that understands and guides you
            </Text>
          </Box>
          
          <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }} gap={8}>
            <Feature
              icon={FaRobot}
              title="Personalized Health Insights"
              text="Chat with AI about your health questions and understand your medical data in simple, clear terms - like having a knowledgeable health assistant in your pocket"
            />
            <Feature
              icon={FaLink}
              title="Connected Health Records"
              text="Seamlessly sync your Apple Health and MyChart data to get a complete picture of your health journey"
            />
            <Feature
              icon={FaChartLine}
              title="Smart Health Management"
              text="Track your medical records and receive AI-powered insights to help you make informed decisions about your health"
            />
            <Feature
              icon={FaLock}
              title="HIPAA Compliant"
              text="Your privacy is our top priority. All your data is encrypted, secured, and handled according to HIPAA standards"
            />
          </Grid>
        </VStack>
      </Container>
    </Box>
  )
}

export default FeaturesSection 